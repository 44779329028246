@keyframes open {
  0% {
    height: 0;
    opacity: 0%;
    transform: translateY(-30px);
  }
  40% {
    height: var(--radix-collapsible-content-height);
    opacity: 0%;
    transform: translateY(-30px);
  }
  100% {
    height: var(--radix-collapsible-content-height);
    opacity: 100%;
    transform: translateY(0);
  }
}

@keyframes close {
  0% {
    height: var(--radix-collapsible-content-height);
    opacity: 100%;
    transform: translateY(0);
  }
  60% {
    height: var(--radix-collapsible-content-height);
    opacity: 0%;
    transform: translateY(-30px);
  }
  100% {
    height: 0;
    opacity: 0%;
    transform: translateY(-30px);
  }
}

.content {
  &[data-state="open"] {
    animation: open 0.8s ease-out forwards;
  }

  &[data-state="closed"] {
    opacity: 0%;
    animation: close 0.8s ease-in-out forwards;
  }
}
