.styledList {
  counter-set: custom-counter;
  @apply space-y-3 self-start;

  li {
    counter-increment: custom-counter;

    &::before {
      content: counter(custom-counter);
      @apply mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-pastel-green font-medium text-black;
    }
  }
}
